<template>
  <div class="row option">
    <div class="col-12">
      <h3>{{ step.title }}</h3>
      <!-- <p v-if="step.description" class="step-description" v-html="step.description"></p> -->
      <!-- categories -->
      <div class="mb-4" v-if="step.name == 'Muster'">
        <button class="btn" :class="{ 'btn-warning': showAll, 'btn-outline-warning': !showAll }" @click="showAllModel">
          <span v-if="!showAll">Alle Muster anzeigen</span>
          <span v-else-if="showAll">Alle Muster verbergen</span>
        </button>
        <span v-if="categories">
          <button v-for="item in categories" :key="item.id" @click="setCategory(item.id)" class="ml-1 btn" :class="{ 'btn-dark': item.selected, 'btn-outline-dark': !item.selected }">
            {{ item.name }}
          </button>
        </span>
      </div>
    </div>
  </div>
  <div v-if="step.css === 'list'" class="row">
    <!-- options -->
    <div v-for="option in options" :key="option.id" class="col-2 mb-4">
      <component :is="(option.type || 'select') + 'Option'" :key="option.id" :option="option" />
    </div>
  </div>
  <div v-else>
    <div v-if="showAll" class="row">
      <div v-for="option in options" :key="option.id" class="col-2 mb-4">
        <component :is="(option.type || 'select') + 'Option'" :key="option.id" :option="option" />
      </div>
    </div>
    <Slider v-if="!showAll"
    ref="slider"
    :itemClass="step.css"
    :init-page="startPage"
    chevron="true"
    :key="componentKey">
      <component v-for="option in options"
      :is="(option.type || 'select') + 'Option'"
      :key="option.id"
      :option="option"
      :type="step.css"/>
    </Slider>
  </div>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import Slider from '~/pages/configurator/common/slider'
import SelectOption from './selectOption'
import CountOption from './countOption'
import { nextTick } from '@vue/runtime-core'
export default {
  name: 'Simpleselect',
  components: {
    Slider,
    selectOption: SelectOption,
    countOption: CountOption,
    checkOption: SelectOption
  },
  setup () {
    const store = useStore()
    const category = ref(null)
    const showAll = ref(false)
    const componentKey = ref(0)
    const page = ref(1)
    const perPage = ref(6)
    const step = computed(() => {
      return store.getters['configurator/currentStep']
    })
    const currentOptions = computed(() => {
      return store.getters['configurator/currentOptions']
    })
    const categories = computed(() => {
      if (step.value.categories) {
        return step.value.categories
      }
    })
    const startPage = computed(() => {
      const index = currentOptions.value.findIndex(o => o.selected)
      const page = Math.ceil((index + 1) / perPage.value)
      if (page === 0) {
        return 1
      } else {
        return page
      }
    })
    const options = computed(() => {
      if (category.value !== null) {
        const o = currentOptions.value.filter(o => o.category && o.category.indexOf(category.value) > -1)
        return o
      }
      nextTick(() => {
        forceRerender()
      })
      return currentOptions.value
    })
    // methods
    function setCategory (catId) {
      category.value = catId
      const selected = categories.value.find(c => c.selected)
      const cat = categories.value.find(c => c.id === catId)
      cat.selected = !cat.selected
      if (!cat.selected) {
        category.value = null
      }
      if (typeof selected !== 'undefined') {
        selected.selected = false
      }
      nextTick(() => {
        forceRerender()
      })
    }
    function showAllModel () {
      showAll.value = !showAll.value
    }
    function forceRerender () {
      componentKey.value += 1
    }
    return {
      category,
      showAll,
      componentKey,
      page,
      perPage,
      step,
      categories,
      startPage,
      options,
      setCategory,
      showAllModel
    }
  }
}
</script>
<style scoped>
.ml-1 {
  margin-left: 5px;
}
</style>

<template>
<div class="slider" ref="slider">
  <div v-if="hasPrev" @click.prevent="prev" class="slider-prev text-primary align-middle">
    <i class="fas fa-chevron-circle-left fa-2x"></i>
  </div>
  <SliderCanvas :from="from" :to="to" :item-class="itemClass">
    <slot />
  </SliderCanvas>
  <div v-if="hasNext" @click.prevent="next" class="slider-next text-primary align-middle">
    <i class="fas fa-chevron-circle-right fa-2x"></i>
  </div>
</div>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
import { defineAsyncComponent } from '@vue/runtime-core'
import { useStore } from 'vuex'
export default {
  components: {
    SliderCanvas: defineAsyncComponent(() => import('~/pages/configurator/common/sliderCanvas'))
  },
  props: {
    initPage: {
      type: Number,
      default: 1
    },
    perPage: {
      type: Number,
      default: 6
    },
    itemClass: {
      default: null
    }
  },
  setup (props, context) {
    const store = useStore()
    const slots = context.slots.default()
    const page = ref(props.initPage)
    const optionCount = computed(() => {
      return (slots[0].children || []).length
    })
    const pageMax = computed(() => {
      const itemCount = (slots[0].children || []).length
      const totalPage = Math.max(0, Math.ceil(itemCount / props.perPage))
      return totalPage
    })
    const from = computed(() => {
      const r = to.value - props.perPage
      store.dispatch('slider/setSliderRange', { type: 'FROM', value: r })
      return r
    })
    const to = computed(() => {
      const r = page.value * props.perPage
      store.dispatch('slider/setSliderRange', { type: 'TO', value: r })
      return r
    })
    const classObject = computed(() => {
      const length = 6
      return {
        'col-2': length === 6,
        'col-3': length < 3
      }
    })
    const hasPrev = computed(() => {
      if (optionCount.value < 6) {
        return false
      } else {
        return true
      }
    })
    const hasNext = computed(() => {
      if (optionCount.value > 6) {
        return true
      } else {
        return false
      }
    })
    // methods
    function prev () {
      if (page.value === 1) {
        page.value = pageMax.value
      } else {
        page.value = Math.max(0, page.value - 1)
      }
    }
    function next () {
      if (pageMax.value === page.value) {
        page.value = 1
      } else {
        page.value = Math.min(page.value + 1, pageMax.value)
      }
    }
    return {
      page,
      pageMax,
      from,
      to,
      classObject,
      hasPrev,
      hasNext,
      prev,
      next
    }
  }
}
</script>
<style scoped>
.slider {
  position: relative;
}
.slider .slider-next, .slider .slider-prev {
  width: 50px;
  cursor: pointer;
}
.slider .slider-prev {
  left: -50px;
}
.slider .slider-next {
  right: -65px;
}
.slider .slider-next, .slider .slider-prev {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
</style>
